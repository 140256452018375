<template>
  <div class="about-me">
    <h1>About the Artist</h1>
    <p>Salena Metreger is not just a voice over artist; she's the vibrant, versatile voice your project needs to soar. With a background in marketing and a strong business acumen, Salena approaches each project as a canvas for your brand's narrative. Her commitment to professionalism is matched only by her dedication to infusing every script with life, energy, and personality.
    <br>
    <br>
    Salena's voice type is as diverse as her skill set. From warm and inviting to authoritative and commanding, she has the versatility to bring any character or concept to life. Whether you need a relatable spokesperson, a reassuring narrator, or a quirky animated character, Salena's voice is the secret ingredient that adds depth and dimension to your project. Her dynamic range allows her to seamlessly transition from corporate narration to quirky character roles, ensuring that each delivery is tailored to resonate with your target audience. Whether it's a commercial, e-learning module, audiobook, or video game.</p>
  </div>
  
  <!-- Equipment -->
  <div class="equipment">
    <h1>Equipment</h1>
    <ul>
      <li>Sound Treated In-Home Studio</li>
      <li>Audio-Technica AT2020 Cardioid Condenser Studio XLR Microphone with Pop Filter</li>
      <li>Beyerdynamic DT 770 PRO Headphones</li>
      <li>Focusrite Scarlett Solo 3rd Gen USB Audio Interface</li>
      <li>Soundforge Audio Studio</li>
      <li>Thinkpad Custom Built Laptop</li>
    </ul>
  </div>
</template>

<style lang="scss">
  .equipment {
    li {
      margin-left: 20px;
      padding-left: 10px;
      font-size: 18px;
    }
  }
</style>